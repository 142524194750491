<template>
  <svg
    id="Component_442_1"
    data-name="Component 442 – 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 26.607 26.581"
  >
    <path
      id="Path_3908"
      :style="iconStyle"
      data-name="Path 3908"
      d="M462.144,392.338a20.977,20.977,0,0,1-3.918-.213,4.406,4.406,0,0,1-3.294-5.927c.806-2.121,2.53-2.781,4.581-2.912,1.067-.069,2.138.006,3.207-.034a1.3,1.3,0,0,0,1.243-.585c.621-1.107.14-3.61-.974-4.235a5.709,5.709,0,0,1-2.232-2.482c-.587-1.092-1.253-2.144-1.834-3.239a2.854,2.854,0,0,0-2.341-1.767h-1.035a7.746,7.746,0,0,0-2.021.716c-2.806,1.689-5.67,3.282-8.474,4.976a7.259,7.259,0,0,1-4.06,1.273,2.259,2.259,0,0,0-2.5,2.435c-.05,4.963-.028,9.927-.011,14.89a2.061,2.061,0,0,0,1.255,2.08,4.218,4.218,0,0,0,1.629.211q9.83-.008,19.66,0c.344,0,.686-.027,1.029-.056a2.164,2.164,0,0,0,1.956-1.721,4.237,4.237,0,0,0,.139-1.332C464.159,392.631,463.894,392.357,462.144,392.338Zm-3.216-16.432,1.253,2.189h-5.01Zm-13.3,2.179c3.384-1.966,6.585-3.828,9.789-5.687a1.117,1.117,0,0,1,1.572.222c-.052.38-.409.428-.651.571-2.4,1.407-4.836,2.75-7.195,4.219A4.745,4.745,0,0,1,445.627,378.085Z"
      transform="translate(-438.461 -370.944)"
      fill="#1fd09d"
    />
    <path
      id="Path_3909"
      data-name="Path 3909"
      d="M509.367,420.66c-1.165.076-2.339.006-3.509.02a3.614,3.614,0,1,0-.011,7.227c1.17.021,2.344-.051,3.51.021a2.444,2.444,0,0,0,2.5-1.253v-4.758A2.393,2.393,0,0,0,509.367,420.66Zm-3.57,4.786a1.189,1.189,0,0,1-1.145-1.215,1.172,1.172,0,0,1,1.157-1.092,1.137,1.137,0,0,1,1.185,1.156A1.162,1.162,0,0,1,505.8,425.446Z"
      transform="translate(-485.246 -407.438)"
      fill="#1fd09d"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '16.199',
    },
    width: {
      type: String,
      default: '16.199',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  // TODO: This icon is not used anywhere
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
